import * as React from "react";
import { Link } from "gatsby";
import Layout from '../../../components/Layout';
import SEOImage from '../../../images/digital-marketing-seo.jpg';
import PaidAds from '../../../images/digital-marketing-paid-ads.jpg';
import SocialMedia from '../../../images/digital-marketing-social-media.jpg';
import DigitalMarketingOverview from '../../../images/digital-marketing-2.jpg';
import DigitalMarketingHealthcare from '../../../images/digital-marketing-3.jpg';
import SEO from '../../../components/Seo';
import CTA from '../../../components/CTA';


const DigitalMarketing = () => {

    return(
        <Layout>
            <SEO title="Digital Marketing for Healthcare" keywords="HealthOne Digital, healthcare website design, healthcare web application, healthcare branding, healthcare digital marketing"/>
            <section>
                <div className="pt-10 bg-white sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
                    <div className="mx-auto max-w-7xl lg:px-8">
                        <div className="lg:grid lg:grid-cols-1 lg:gap-8">
                            <div className="mx-auto px-4 sm:px-6 sm:text-center lg:px-0 lg:text-center lg:flex lg:items-center">
                                <div className="py-8 sm:py-8 lg:py-24">
                                    <h1 className="mt-4 mb-5 text-5xl tracking-tight font-extrabold sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                                         <span className="block text-blue-900">Digital Marketing</span> 
                                         <span className="block mt-0 lg:mt-2 text-blue-500">For Healthcare</span>
                                    </h1>
                                    <span className="text-base text-gray-800 sm:text-xl lg:text-lg xl:text-xl">
                                        <Link className="hover:text-white" to="/services">SERVICES</Link> 
                                        <span> / </span> 
                                        DIGITAL MARKETING
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Content */}
            <section>
                <div className="relative bg-blue-50 py-8 lg:py-16">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                        <div className="relative mx-auto px-4 sm:max-w-3xl sm:px-6 lg:px-0">
                            <div className="pt-12 sm:pt-0 lg:pt-12">
                                <h2 className="text-3xl text-blue-900 font-extrabold tracking-tight sm:text-4xl">
                                Understanding the Digital Marketing Ecosystem
                                </h2>
                                <div className="mt-6 text-gray-500 space-y-6">
                                    <p className="text-xl leading-8">
                                    At HealthOne we have had many clients come to us after becoming frustrated with marketing agency jargon.
                                    The digital marketing ecosystem is often shrouded in uneeded complexity. While in advanced use cases, this complexity may be neccesary we have often found that foucsing on fundamentals yeilds great results. 
                                    </p>
                                    <p className="text-xl leading-8">
                                    We always start with assessing what your goals are with your digital marketing efforts. Is it more patients through the door? Is it more visitors to your website? Is it a combination of both? 
                                    These goals all require slightly different approaches. 
                                    </p>
                                    <p className="text-xl leading-8">
                                    We will demystify the digital marketing services that we offer namely SEO (Search Engine Optimization), Paid Digital Advertising and Social Media Marketing. 
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="relative mt-10 sm:py-16 lg:py-0">
                            <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:left-72">
                                <div className="absolute inset-y-0 left-1/2 w-full bg-gray-50 rounded-r-3xl lg:left-72"></div>
                                <svg className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12" width="404" height="392" fill="none" viewBox="0 0 404 392">
                                <defs>
                                    <pattern id="02f20b47-fd69-4224-a62a-4c9de5c763f7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                                    </pattern>
                                </defs>
                                <rect width="404" height="392" fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
                                </svg>
                            </div>
                            
                            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                                <div className="relative pt-64 pb-48 rounded-2xl shadow-xl overflow-hidden">
                                    <img className="absolute inset-0 h-full w-full object-cover" src={DigitalMarketingOverview} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*Digital Marketing Sub Services*/}
            <section>
                <div className="relative bg-gray-50 py-24 px-4 sm:px-6 lg:px-8">
                    <div className="absolute inset-0">
                        <div className="bg-white h-1/3 sm:h-2/3"></div>
                    </div>
                    <div className="relative max-w-7xl mx-auto">
                        <div className="text-center">
                            <h2 className="text-3xl tracking-tight font-extrabold text-blue-900 sm:text-4xl">
                                The Parts That Make Up Digital Marketing
                            </h2>
                        </div>
                        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                        <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                            <div className="flex-shrink-0">
                            <img className="h-48 w-full object-cover" src={SEOImage} alt="A person searching google on his mobile phone."/>
                            </div>
                            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                                <div className="flex-1">
                                    <p className="text-sm font-medium text-blue-600">
                                        Digital Marketing
                                    </p>
                                    <p className="mt-2 text-xl font-semibold text-gray-900">
                                        Search Engine Optimization
                                    </p>
                                    <p className="mt-3 text-lg leading-8 text-gray-500">
                                    SEO is the practice of increasing the quantity and quality of traffic to your website through organic search engine results.
                                    </p>
                                </div>
                                <div className="mt-6 flex items-center">
                                    <Link to="/services/digital-marketing/search-engine-optimization" className="inline-flex mt-8 px-4 py-2 w-full border border-transparent text-base justify-center font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-blue-600 to-blue-600 hover:from-blue-700 hover:to-blue-700">
                                        Learn More
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                            <div className="flex-shrink-0">
                            <img className="h-48 w-full object-cover" src={PaidAds} alt="A dashboard showing paid advertising statistics."/>
                            </div>
                            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                                <div className="flex-1">
                                    <p className="text-sm font-medium text-blue-600">
                                        Digital Marketing
                                    </p>
                                    <p className="mt-2 text-xl font-semibold text-gray-900">
                                        Paid Digital Advertising
                                    </p>
                                    <p className="mt-3 text-lg leading-8 text-gray-500">
                                       Paid Digital Advertising invovles paying search engines and social media platforms to display your ads by search results and in user feeds.
                                    </p>
                                </div>
                                <div className="mt-6 flex items-center">
                                    <Link to="/services/digital-marketing/digital-advertising" className="inline-flex mt-8 px-4 py-2 w-full border border-transparent text-base justify-center font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-blue-600 to-blue-600 hover:from-blue-700 hover:to-blue-700">
                                        Learn More
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                            <div className="flex-shrink-0">
                            <img className="h-48 w-full object-cover" src={SocialMedia} alt="A mobile phone showing app icons for facebook, instagram and twitter."/>
                            </div>
                            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                            <div className="flex-1">
                                <p className="text-sm font-medium text-blue-600">
                                    Digital Marketing
                                </p>
                                <p className="mt-2 text-xl font-semibold text-gray-900">
                                    Social Media Marketing
                                </p>
                                <p className="mt-3 text-lg leading-8 text-gray-500">
                                Social Media Marketing involves creating content to promote your healthcare practice on various social media platforms such as Facebook, Instagram, and Twitter.
                                </p>
                                <div className="mt-6 flex items-center">
                                    <Link to="/services/digital-marketing/social-media-marketing"  className="inline-flex mt-8 px-4 py-2 w-full border border-transparent text-base justify-center font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-blue-600 to-blue-600 hover:from-blue-700 hover:to-blue-700">
                                        Learn More
                                    </Link>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>


            {/* PROCESS SECTION */}
            <section>
                <div className="relative bg-gray-50">
                    <div className="h-80 absolute bottom-0 xl:inset-0 xl:h-auto xl:grid xl:grid-cols-2">
                        <div className="h-full xl:relative xl:col-start-2">
                        <img className="h-full w-full object-cover hidden xl:block rounded-t-lg opacity-100 xl:absolute xl:inset-0" src={DigitalMarketingHealthcare} alt="Doctor writing notes"/>
                        </div>
                    </div>
                    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
                        <div className="relative pt-12 pb-24 sm:pt-12 xl:col-start-1 xl:pb-24">
                     
                            <p className="mt-3 text-3xl font-extrabold text-blue-900">More Patients, Less Reports. Our Approach to Digital Marketing is <span className="text-blue-500">Simple & Transprent.</span> </p>
                            <p className="mt-5 text-xl leading-8 text-gray-500">
                                If you're a physical practice, the goal is to bring more patients through the door. 
                                If you're an informational healthcare resource, the goal is to bring more people to your website. 
                                It's important to have clear goals when implementing a digital marketing strategy. 
                            </p>
                            <p className="mt-5 text-xl leading-8 text-gray-500">
                                We ensure that we cleary understand your goals and implement only the required digital marketing strategies. We do not overwhelm you
                                with unneccesary marketing reports. 
                            </p>

                            <p className="mt-5 text-xl leading-8 text-gray-500">
                                We also ensure that you have full access to any ongoing digital marketing campaigns so you can check for yourself how well they're doing.
                            </p>
                        </div>
                    </div>
                </div>
            </section>


            {/* CTA */}
            <CTA/>
        </Layout>
    )
}

export default DigitalMarketing;